























































































import Vue from "vue";
import Component from "vue-class-component";
import { User, Subscription } from "@/store/auth";
import { Auth } from "@/store/modules";
import moment from "moment";

import FancyButton from "@/components/FancyButton.vue";
import Container from "@/components/Container.vue";

@Component({
  components: {
    FancyButton,
    Container,
  },
})
export default class Account extends Vue {
  containers: any = {};
  logs: any = [];
  isLoading = true;
  loadingError = "";
  headers = [
    { text: "Type", value: "type" },
    { text: "Restaurant", value: "restaurant" },
    { text: "City", value: "city" },
    { text: "Amount", value: "amount" },
    { text: "Date", value: "date" },
  ];
  showInactive = false;
  historyDialog = false;

  get companyName() {
    return (Auth.user as User).company && (Auth.user as User).company.name;
  }

  get name() {
    return (Auth.user as User).name;
  }

  get email() {
    return (Auth.user as User).email;
  }

  get picture() {
    return (Auth.user as User).picture;
  }

  get user() {
    return (Auth.user as User);
  }

  get noContainers() {
    return Object.keys(this.containers).length === 0;
  }

  get totalUses() {
    if (!this.logs) {
      return 0;
    }

    return this.logs.reduce((agg: number, cur: any) => {
      if (cur.type !== "Take") {
        return agg;
      }

      return agg + cur.amount;
    }, 0);
  }

  async refresh() {
    this.isLoading = true;
    try {
      const { containers, logs } = await Auth.myContainers();
      const filteredContainers: any = {};
      Object.keys(containers).forEach((id) => {
        if (containers[id] > 0) {
          filteredContainers[id] = containers[id];
        }
      });
      this.containers = filteredContainers;
      this.logs = logs;
      this.loadingError = "";
    } catch (err) {
      this.loadingError = err.message;
    }
    this.isLoading = false;
  }

  mounted() {
    this.refresh();
    Auth.refreshAccountData();
  }
}
